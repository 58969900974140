import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/service/rest.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {

  
  constructor(private rest : RestService ,  private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getVideos()
  }

  getVideos(){
    this.rest.getvideos().subscribe(res => {
      console.log("videos",res)
      this.videosArr = res
      this.videosArr.forEach(element => {
        element.URL = this._sanitizer.bypassSecurityTrustResourceUrl(element.URL)
      });
    })
  }
  videosArr:any


}
