import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RestService } from 'src/app/service/rest.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  isSubmit
  subscription: Subscription;
  constructor(private rest :RestService) { }

  ngOnInit() {
    this.subscription = this.rest.getObsData().subscribe(res => {
      console.log(res)
      this.isSubmit = localStorage.getItem("isSubmit")
    })
  }

}
     