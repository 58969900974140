import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/service/rest.service';

@Component({
  selector: 'app-prize-requirment',
  templateUrl: './prize-requirment.component.html',
  styleUrls: ['./prize-requirment.component.css']
})
export class PrizeRequirmentComponent implements OnInit {

  requrment;
  date
  constructor(private rest : RestService) { }

  ngOnInit() {
    this.rest.prizeReq().subscribe((res : any) => {
      console.log(res)
      this.requrment = res
    })
    this.rest.descrption().subscribe(res => {
      console.log(res)
      this.date = res[0].END_DATE
    })
  }

}
