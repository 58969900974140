import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/service/rest.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.css']
})
export class UploadFilesComponent implements OnInit {

  constructor(private rest: RestService,private router: Router) { }
  name;
  categori
  ngOnInit() {
    this.name = localStorage.getItem('name')
    this.categori = localStorage.getItem('categori')
  }
  myFiles = [];
  myFileTolApi = []
  base64textString:any
  id = 31
  onFileChange(event: any) {
    console.log(event.target.files[0])
    for (var i = 0; i < event.target.files.length; i++) {

      if (event.target.files[i].type == "image/png" || event.target.files[i].type == "image/jpg" || event.target.files[i].type == "image/jpeg") {
        let item = event.target.files[i]
        this.myFileTolApi.push(event.target.files[i]);
        this.getBase64(item)

      } else {
        this.myFiles.push(event.target.files[i]);
        this.myFileTolApi.push(event.target.files[i]);
      }
    }
  }

  getBase64(event) {
    let me = this;
    let reader = new FileReader();
    reader.readAsDataURL(event);
    reader.onload = function () {
      me.base64textString = reader.result;
      let obj = {
        base64: me.base64textString,
        type: "image",
      }
      me.myFiles.push(obj);
      console.log(me.myFiles)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  // sendFiles(){
  //   this.rest.testInputFile(this.myFileTolApi,this.id).subscribe(res => {
  //     console.log(res)
  //     this.router.navigateByUrl('/confirm_upload');
  //   }
  //   ,erorr => {
  //     alert("لقد حدث خطأ ما برجاء اعادة المحاولة")
  //   })
  // }

}
