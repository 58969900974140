import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/service/rest.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  currentPage = 1
  count = 200
  news_list;
  constructor(private rest : RestService , private route : Router) { }

  ngOnInit() {
    this.getNews()
  }

  getNews(){
    this.rest.getNews(this.currentPage,this.count).subscribe(res => {
      console.log("news" , res)
      this.news_list = res
    })
  }

  newsDetails(id){
    this.route.navigate(['/news_details',id])
  }

}
