import { Component, Inject } from '@angular/core';
import { RestService } from 'src/app/service/rest.service';
import { Observable, interval } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  requrment
  subscribtion:any

  constructor(private rest : RestService) { }


  ngOnInit() {
    
    this.rest.prizeReq().subscribe((res : any) => {
      this.requrment = res
      console.log(res)
    })

    //use window.scrollY
    var scrollpos = window.scrollY;
    var nav = document.getElementById("nav");

    function add_class_on_scroll() {
      nav.classList.add("scroll");
    }

    function remove_class_on_scroll() {
      nav.classList.remove("scroll");
    }

    window.addEventListener('scroll', function () {
      //Here you forgot to update the value
      scrollpos = window.scrollY;

      if (scrollpos > 100) {
        add_class_on_scroll();
      }
      else {
        remove_class_on_scroll();
      }
    });

  }



}
