import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-categoris',
  templateUrl: './categoris.component.html',
  styleUrls: ['./categoris.component.css']
})
export class CategorisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
