import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule , NgxSpinnerService} from "ngx-spinner";
import {MatStepperModule} from '@angular/material/stepper';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { CountdownModule, CountdownGlobalConfig } from 'ngx-countdown';
import { HomeComponent } from './mainCopmponent/home/home.component';
import { LiteraryComponent } from './pages/literary/literary.component';
import { ArtsComponent } from './pages/arts/arts.component';
import { CreativityComponent } from './pages/creativity/creativity.component';
import { NavComponent } from './mainCopmponent/nav/nav.component';
import { FooterComponent } from './mainCopmponent/footer/footer.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { CategorisComponent } from './pages/categoris/categoris.component';
import { SubmitFormCategoriComponent } from './pages/submit-form-categori/submit-form-categori.component';
import { AboutComponent } from './pages/about/about.component';
import { PrizeRequirmentComponent } from './pages/prize-requirment/prize-requirment.component';
import { QuestionsComponent } from './pages/questions/questions.component';
import { WinnersComponent } from './pages/winners/winners.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlModule } from 'ngx-owl-carousel';
import { JudgerComponent } from './pages/judger/judger.component';
import { LegstlationComponent } from './pages/legstlation/legstlation.component';
import { UploadFilesComponent } from './pages/upload-files/upload-files.component';
import { ConfirmComponent } from './pages/confirm/confirm.component';
import { ConfirmUploadComponent } from './pages/confirm-upload/confirm-upload.component';
import { BooksComponent } from './pages/books/books.component';
import { NewsComponent } from './pages/news/news.component';
import { NewsDetailsComponent } from './pages/news-details/news-details.component';
import { VideosComponent } from './pages/videos/videos.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LiteraryComponent,
    ArtsComponent,
    CreativityComponent,
    NavComponent,
    FooterComponent,
    SignUpComponent,
    CategorisComponent,
    SubmitFormCategoriComponent,
    AboutComponent,
    PrizeRequirmentComponent,
    QuestionsComponent,
    WinnersComponent,
    JudgerComponent,
    LegstlationComponent,
    UploadFilesComponent,
    ConfirmComponent,
    ConfirmUploadComponent,
    BooksComponent,
    NewsComponent,
    NewsDetailsComponent,
    VideosComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    MatSelectModule,
    MatToolbarModule,
    MatIconModule,
    MatSnackBarModule,
    CountdownModule,
    CarouselModule,
    OwlModule
  ],
  providers: [NgxSpinnerService , { provide: CountdownGlobalConfig}],
  bootstrap: [AppComponent]
})
export class AppModule { }
