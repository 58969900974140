import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/service/rest.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private rest : RestService) { }

  ngOnInit() {
  }


  ADD_INSTAGRAM_VIEW(){
    this.rest.ADD_INSTAGRAM_VIEW().subscribe(res => {
      console.log(res)
    })
  }

  ADD_FACEBOOK_VIEW(){
    this.rest.ADD_FACEBOOK_VIEW().subscribe(res =>{
      console.log(res)
    })
  }



}
