import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { RestService } from 'src/app/service/rest.service';
import { Router } from '@angular/router';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-submit-form-categori',
  templateUrl: './submit-form-categori.component.html',
  styleUrls: ['./submit-form-categori.component.css']
})
export class SubmitFormCategoriComponent implements OnInit {

  submitForm: FormGroup;
  submitSecoundForm: FormGroup
  submitThirdForm: FormGroup
  adabArr = []
  fnonArr = []
  abtkarArr = []
  gendarArr = []
  Filed_ID: number = 0
  ifIndexSartThree = true
  Filed = 0
  categoriName: string
  titleName: string
  base64textString
  userChecked = false
  submitted = false
  goverments;
  constructor(private _formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private rest: RestService, private router: Router) { }

  ngOnInit() {

    this.getGendar()
    this.getCtegoris()
    this.getGover()
    this.getNationlaty()
    this.submitForm = this._formBuilder.group({
      FIRST_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      MIDDLE_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      LAST_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      LAST_NAME2: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      PRIZE_KNOWLEDGE_SOURCE: [''],
      GENDER_ID: ['', Validators.required],
      DAY: ['', Validators.required],
      MONTH: ['', Validators.required],
      YEAR: ['', Validators.required],
      SCHOOL_NAME: [''],
      SCHOOL_ADDRESS:  [''],
      ACADEMIC_YEAR: [''],
      PARFIRST_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      PARMIDDLE_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      PARLAST_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      PARLAST_NAME2: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      // PARENT_NAT_NUMBER: ['', [Validators.required ,Validators.min(10000000000000) , Validators.max(100000000000000)]],
      CURRENT_ADDRESS: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      LAND_PHONE_NUMBER: [''],
      MOBILE_PHONE_NUMBER: ['', [Validators.required]],
      WHATSAPP_NUMBER: ['', [Validators.required]],
      GOVERNORATE_ID: ['', Validators.required],
      NATIONALITY_ID: ['', Validators.required],
      // Password: ['', Validators.required],
      SUB_APPLICATION_FIELD_ID: ['', Validators.required],
      CHILD_NAT_NUMBER: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(14)]],
    });
    this.submitSecoundForm = this._formBuilder.group({
      FIRST_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      MIDDLE_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      LAST_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      LAST_NAME2: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      PRIZE_KNOWLEDGE_SOURCE: [''],
      GENDER_ID: ['', Validators.required],
      DAY: ['', Validators.required],
      MONTH: ['', Validators.required],
      YEAR: ['', Validators.required],
      NATIONALITY_ID: ['', Validators.required],
      SCHOOL_NAME: [''],
      SCHOOL_ADDRESS:  [''],
      ACADEMIC_YEAR: [''],
      PARFIRST_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      PARMIDDLE_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      PARLAST_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      PARLAST_NAME2: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      // PARENT_NAT_NUMBER: ['', [Validators.required ,Validators.min(10000000000000) , Validators.max(100000000000000)]],
      CURRENT_ADDRESS: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      LAND_PHONE_NUMBER: [''],
      MOBILE_PHONE_NUMBER: ['', [Validators.required]],
      WHATSAPP_NUMBER: ['', [Validators.required]],
      GOVERNORATE_ID: ['', Validators.required],
      // Password: ['', Validators.required],
      SUB_APPLICATION_FIELD_ID: [''],
      CHILD_NAT_NUMBER: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(14)]],
    });
    this.submitThirdForm = this._formBuilder.group({
      FIRST_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      MIDDLE_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      LAST_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      LAST_NAME2: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      PRIZE_KNOWLEDGE_SOURCE: [''],
      GENDER_ID: ['', Validators.required],
      DAY: ['', Validators.required],
      MONTH: ['', Validators.required],
      YEAR: ['', Validators.required],
      SCHOOL_NAME: [''],
      SCHOOL_ADDRESS:  [''],
      ACADEMIC_YEAR: [''],
      PARFIRST_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      PARMIDDLE_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      PARLAST_NAME: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      PARLAST_NAME2: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      // PARENT_NAT_NUMBER: ['', [Validators.required ,Validators.min(10000000000000) , Validators.max(100000000000000)]],
      CURRENT_ADDRESS: ['', [Validators.required, Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      LAND_PHONE_NUMBER: [''],
      MOBILE_PHONE_NUMBER: ['', [Validators.required]],
      WHATSAPP_NUMBER: ['', [Validators.required]],
      GOVERNORATE_ID: ['', Validators.required],
      NATIONALITY_ID: ['', Validators.required],
      // Password: ['', Validators.required],
      SUB_APPLICATION_FIELD_ID: [''],
      CHILD_NAT_NUMBER: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(14)]],
    });

    var submitCat = document.getElementById("submit-cat");
    var chooseCat = document.getElementById("choose-cat");
    var uploadDocDiv = document.getElementById("upload-doc");
    submitCat.onclick = function () {
      chooseCat.classList.add("d-none");
      uploadDocDiv.classList.remove("d-none");
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.submitForm.controls;
  }
  get ff(): { [key: string]: AbstractControl } {
    return this.submitSecoundForm.controls;
  }
  get fff(): { [key: string]: AbstractControl } {
    return this.submitThirdForm.controls;
  }

  getvalue(value) {
    console.log(value.target.value)
    let v = value.target.value
    if (v[0] != 3) {
      this.ifIndexSartThree = false
    } else {
      this.ifIndexSartThree = true
    }

  }


  Submit() {
    this.submitted = true
    let list_objects = []
    console.log(this.submitForm.value)
    this.rest.sendFile(this.images_upload)
    if (this.submitForm.valid) {
      if (this.multiCheckOne == true && this.multiCheckTow == false) {

        this.submitted = false
        let objForm = this.submitForm.value
        let secoundForm = this.submitSecoundForm.value

        objForm.FULL_NAME = this.submitForm.value.FIRST_NAME + " " + this.submitForm.value.MIDDLE_NAME + " " + this.submitForm.value.LAST_NAME + " " + this.submitForm.value.LAST_NAME2
        objForm.BIRTH_DATE = this.submitForm.value.DAY + "/" + this.submitForm.value.MONTH + "/" + this.submitForm.value.YEAR
        objForm.PARENT_NAME = this.submitForm.value.PARFIRST_NAME + " " + this.submitForm.value.PARMIDDLE_NAME + " " + this.submitForm.value.PARLAST_NAME + " " + this.submitForm.value.PARLAST_NAME2
        objForm.GENDER_ID = + objForm.GENDER_ID
        objForm.SUB_APPLICATION_FIELD_ID = + objForm.SUB_APPLICATION_FIELD_ID
        objForm.GOVERNORATE_ID = + objForm.GOVERNORATE_ID
        objForm.status = true
        delete objForm.FIRST_NAME
        delete objForm.MIDDLE_NAME
        delete objForm.LAST_NAME
        delete objForm.LAST_NAME2
        delete objForm.PARFIRST_NAME
        delete objForm.PARMIDDLE_NAME
        delete objForm.PARLAST_NAME
        delete objForm.PARLAST_NAME2
        delete objForm.DAY
        delete objForm.MONTH
        delete objForm.YEAR
        list_objects.push(objForm)
        // secound form
        secoundForm.FULL_NAME = this.submitSecoundForm.value.FIRST_NAME + " " + this.submitSecoundForm.value.MIDDLE_NAME + " " + this.submitSecoundForm.value.LAST_NAME + " " + this.submitSecoundForm.value.LAST_NAME2
        secoundForm.BIRTH_DATE = this.submitSecoundForm.value.DAY + "/" + this.submitSecoundForm.value.MONTH + "/" + this.submitSecoundForm.value.YEAR
        secoundForm.PARENT_NAME = this.submitSecoundForm.value.PARFIRST_NAME + " " + this.submitSecoundForm.value.PARMIDDLE_NAME + " " + this.submitSecoundForm.value.PARLAST_NAME + " " + this.submitSecoundForm.value.PARLAST_NAME2
        secoundForm.GENDER_ID = + secoundForm.GENDER_ID
        secoundForm.SUB_APPLICATION_FIELD_ID = + objForm.SUB_APPLICATION_FIELD_ID
        secoundForm.GOVERNORATE_ID = + secoundForm.GOVERNORATE_ID
        secoundForm.status = true
        delete secoundForm.FIRST_NAME
        delete secoundForm.MIDDLE_NAME
        delete secoundForm.LAST_NAME
        delete secoundForm.LAST_NAME2
        delete secoundForm.PARFIRST_NAME
        delete secoundForm.PARMIDDLE_NAME
        delete secoundForm.PARLAST_NAME
        delete secoundForm.PARLAST_NAME2
        delete secoundForm.DAY
        delete secoundForm.MONTH
        delete secoundForm.YEAR
        list_objects.push(secoundForm)

        console.log(list_objects)
      } else if (this.multiCheckTow == true) {
        this.submitted = false
        let objForm = this.submitForm.value
        let secoundForm = this.submitSecoundForm.value
        let thirdForm = this.submitThirdForm.value

        objForm.FULL_NAME = this.submitForm.value.FIRST_NAME + " " + this.submitForm.value.MIDDLE_NAME + " " + this.submitForm.value.LAST_NAME + " " + this.submitForm.value.LAST_NAME2
        objForm.BIRTH_DATE = this.submitForm.value.DAY + "/" + this.submitForm.value.MONTH + "/" + this.submitForm.value.YEAR
        objForm.PARENT_NAME = this.submitForm.value.PARFIRST_NAME + " " + this.submitForm.value.PARMIDDLE_NAME + " " + this.submitForm.value.PARLAST_NAME + " " + this.submitForm.value.PARLAST_NAME2
        objForm.GENDER_ID = + objForm.GENDER_ID
        objForm.SUB_APPLICATION_FIELD_ID = + objForm.SUB_APPLICATION_FIELD_ID
        objForm.GOVERNORATE_ID = + objForm.GOVERNORATE_ID
        objForm.status = true
        delete objForm.FIRST_NAME
        delete objForm.MIDDLE_NAME
        delete objForm.LAST_NAME
        delete objForm.LAST_NAME2
        delete objForm.PARFIRST_NAME
        delete objForm.PARMIDDLE_NAME
        delete objForm.PARLAST_NAME
        delete objForm.PARLAST_NAME2
        delete objForm.DAY
        delete objForm.MONTH
        delete objForm.YEAR
        list_objects.push(objForm)
        // secound form
        secoundForm.FULL_NAME = this.submitSecoundForm.value.FIRST_NAME + " " + this.submitSecoundForm.value.MIDDLE_NAME + " " + this.submitSecoundForm.value.LAST_NAME + " " + this.submitSecoundForm.value.LAST_NAME2
        secoundForm.BIRTH_DATE = this.submitSecoundForm.value.DAY + "/" + this.submitSecoundForm.value.MONTH + "/" + this.submitSecoundForm.value.YEAR
        secoundForm.PARENT_NAME = this.submitSecoundForm.value.PARFIRST_NAME + " " + this.submitSecoundForm.value.PARMIDDLE_NAME + " " + this.submitSecoundForm.value.PARLAST_NAME + " " + this.submitSecoundForm.value.PARLAST_NAME2
        secoundForm.GENDER_ID = + secoundForm.GENDER_ID
        secoundForm.SUB_APPLICATION_FIELD_ID = + objForm.SUB_APPLICATION_FIELD_ID
        secoundForm.GOVERNORATE_ID = + secoundForm.GOVERNORATE_ID
        secoundForm.status = true
        delete secoundForm.FIRST_NAME
        delete secoundForm.MIDDLE_NAME
        delete secoundForm.LAST_NAME
        delete secoundForm.LAST_NAME2
        delete secoundForm.PARFIRST_NAME
        delete secoundForm.PARMIDDLE_NAME
        delete secoundForm.PARLAST_NAME
        delete secoundForm.PARLAST_NAME2
        delete secoundForm.DAY
        delete secoundForm.MONTH
        delete secoundForm.YEAR
        list_objects.push(secoundForm)

        // third form

        thirdForm.FULL_NAME = this.submitThirdForm.value.FIRST_NAME + " " + this.submitThirdForm.value.MIDDLE_NAME + " " + this.submitThirdForm.value.LAST_NAME + " " + this.submitThirdForm.value.LAST_NAME2
        thirdForm.BIRTH_DATE = this.submitThirdForm.value.DAY + "/" + this.submitThirdForm.value.MONTH + "/" + this.submitThirdForm.value.YEAR
        thirdForm.PARENT_NAME = this.submitThirdForm.value.PARFIRST_NAME + " " + this.submitThirdForm.value.PARMIDDLE_NAME + " " + this.submitThirdForm.value.PARLAST_NAME + " " + this.submitThirdForm.value.PARLAST_NAME2
        thirdForm.GENDER_ID = + thirdForm.GENDER_ID
        thirdForm.SUB_APPLICATION_FIELD_ID = + objForm.SUB_APPLICATION_FIELD_ID
        thirdForm.GOVERNORATE_ID = + thirdForm.GOVERNORATE_ID
        thirdForm.status = true
        delete thirdForm.FIRST_NAME
        delete thirdForm.MIDDLE_NAME
        delete thirdForm.LAST_NAME
        delete thirdForm.LAST_NAME2
        delete thirdForm.PARFIRST_NAME
        delete thirdForm.PARMIDDLE_NAME
        delete thirdForm.PARLAST_NAME
        delete thirdForm.PARLAST_NAME2
        delete thirdForm.DAY
        delete thirdForm.MONTH
        delete thirdForm.YEAR
        list_objects.push(thirdForm)
      }
      else {
        if (this.submitForm.valid && this.userChecked == true) {
          this.submitted = false
          console.log(this.submitForm.valid)

          let objForm = this.submitForm.value

          objForm.FULL_NAME = this.submitForm.value.FIRST_NAME + " " + this.submitForm.value.MIDDLE_NAME + " " + this.submitForm.value.LAST_NAME + " " + this.submitForm.value.LAST_NAME2
          objForm.BIRTH_DATE = this.submitForm.value.DAY + "/" + this.submitForm.value.MONTH + "/" + this.submitForm.value.YEAR
          objForm.PARENT_NAME = this.submitForm.value.PARFIRST_NAME + " " + this.submitForm.value.PARMIDDLE_NAME + " " + this.submitForm.value.PARLAST_NAME + " " + this.submitForm.value.PARLAST_NAME2
          objForm.GENDER_ID = + objForm.GENDER_ID
          objForm.SUB_APPLICATION_FIELD_ID = + objForm.SUB_APPLICATION_FIELD_ID
          objForm.GOVERNORATE_ID = + objForm.GOVERNORATE_ID
          objForm.status = true
          delete objForm.FIRST_NAME
          delete objForm.MIDDLE_NAME
          delete objForm.LAST_NAME
          delete objForm.LAST_NAME2
          delete objForm.PARFIRST_NAME
          delete objForm.PARMIDDLE_NAME
          delete objForm.PARLAST_NAME
          delete objForm.PARLAST_NAME2
          delete objForm.DAY
          delete objForm.MONTH
          delete objForm.YEAR
          console.log(objForm)
          list_objects.push(objForm)
          // this.rest.postFormSumbit(objForm).subscribe((res : any) => {

          //   console.log(res)
          //   localStorage.setItem('id',res.Message)
          //   this.spinner.hide();
          // },(erorr : any) => {
          //   console.log(erorr)
          //   this.spinner.hide();
          //   alert (erorr.error.Message)
          // })
        }
      }
      localStorage.setItem('objForm', JSON.stringify(list_objects))
      this.router.navigateByUrl('/confirm');
    }


  }

  // getTitls(value){
  //   console.log(value)
  // }


  status_multi_persons = false
  getTitls(e) {
    console.log(e.target.options[e.target.selectedIndex].text)
    if (e.target.options[e.target.selectedIndex].text == 'التطبيقات والمواقع' || e.target.options[e.target.selectedIndex].text == 'الابتكارات العلمية') {
      this.status_multi_persons = true
    } else {
      this.status_multi_persons = false
    }
    localStorage.setItem('name', e.target.options[e.target.selectedIndex].text)
    localStorage.setItem('categori', e.target.options[e.target.selectedIndex].parentNode.getAttribute('label'))
  }
  getGendar() {
    this.rest.getGender().subscribe((res: []) => {
      this.gendarArr = res
    })
  }
  nation_arr 
  getNationlaty(){
    this.rest.getNationlaty().subscribe((res: []) => {
      console.log(res)
      this.nation_arr = res
    })
  }

  getGover() {
    this.rest.GetGovernate().subscribe(res => {
      this.goverments = res
    })
  }

  getCtegoris() {
    this.rest.getSpecifications().subscribe((res: []) => {
      console.log(res)

    })
    this.rest.getSpecificationsbyId(1).subscribe((res: []) => {
      console.log("id adab", res)
      this.adabArr = res
    })
    this.rest.getSpecificationsbyId(2).subscribe((res: []) => {
      console.log("id fnon", res)
      this.fnonArr = res
    })
    this.rest.getSpecificationsbyId(4).subscribe((res: []) => {
      this.abtkarArr = res
      console.log("id abda3", res)
    })
  }

  onItemChange(value, name, TITLE) {
    this.categoriName = name
    this.titleName = TITLE
    this.Filed = value
    console.log(value)
  }

  chooseCategri() {
    this.Filed_ID = this.Filed
    localStorage.setItem('name', this.titleName)
    localStorage.setItem('categori', this.categoriName)
  }

  get PRSGENDER_ID() {
    return this.submitForm.get('GENDER_ID');
  }

  changePRSGENDER_ID(e) {
    this.PRSGENDER_ID.setValue(e.target.value, {
      onlySelf: true
    })
  }


  onUserChecked(event) {
    this.userChecked = event
    console.log(event)
  }

  MultiChecked = false
  multiCheckOne = false
  multiCheckTow = false
  onMultiChecked(event) {
    this.MultiChecked = event
    console.log(event)
  }

  coutn_members(e) {
    console.log(e.target.value)
    if (e.target.value == 2) {
      console.log(true)
      this.multiCheckOne = true
      this.multiCheckTow = false
    } else if (e.target.value == 3) {
      this.multiCheckOne = true
      this.multiCheckTow = true
    }
  }


  getNationaltyId(){
    let id =  this.submitForm.get('NATIONALITY_ID').value
    let id2 =  this.submitSecoundForm.get('NATIONALITY_ID').value
    let id3 =  this.submitThirdForm.get('NATIONALITY_ID').value
    console.log(id)
   
    if(id == 1){
      this.submitForm.get('GOVERNORATE_ID').setValidators([Validators.required])
      this.submitForm.get('GOVERNORATE_ID').updateValueAndValidity()
    }else {
      this.submitForm.get('GOVERNORATE_ID').clearValidators()
      this.submitForm.get('GOVERNORATE_ID').updateValueAndValidity()
    }
    // 
    if(id2 == 1){
      this.submitForm.get('GOVERNORATE_ID').setValidators([Validators.required])
      this.submitForm.get('GOVERNORATE_ID').updateValueAndValidity()
    }else {
      this.submitForm.get('GOVERNORATE_ID').clearValidators()
      this.submitForm.get('GOVERNORATE_ID').updateValueAndValidity()
    }
    // 
    if(id3 == 1){
      this.submitForm.get('GOVERNORATE_ID').setValidators([Validators.required])
      this.submitForm.get('GOVERNORATE_ID').updateValueAndValidity()
    }else {
      this.submitForm.get('GOVERNORATE_ID').clearValidators()
      this.submitForm.get('GOVERNORATE_ID').updateValueAndValidity()
    }
  }

 images_upload : any = [] 
  onFileChange(e){
    let images :any = []
    this.images_upload.push( e.target.files[0])
    console.log(this.images_upload)
    // const file = (event.target as HTMLInputElement).files[0];
    // this.submitForm.controls['file_upload'].setValue(e.target.files[0]) 
    // this.submitForm.patchValue({ file_upload: file});
  }
}
