import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from 'src/app/service/rest.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {
  name
  categori
  id;
  objOfGift 
  isSubmit
  subscription: Subscription;
  constructor(private rest: RestService, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.isSubmit = localStorage.getItem("isSubmit")
    //  this.objOfGift =   JSON.parse(localStorage.getItem('objForm'));
     console.log(this.objOfGift )
     this.rest.file$.subscribe(res => {
       if(Object.keys(res).length != 0) this.myFileTolApi.push(...res)
     })
     console.log("myFileTolApi",this.myFileTolApi)
     this.objOfGift = localStorage.getItem('objForm')
    this.name = localStorage.getItem('name')
    this.categori = localStorage.getItem('categori')
    this.id = localStorage.getItem('id')
  }

  myFiles = [];
  myFileTolApi = []
  base64textString: any
  btnStatus = false
  onFileChange(event: any) {
    console.log(event.target.files[0])
    if(event.target.files[0]){
      this.btnStatus = true
    }
    for (var i = 0; i < event.target.files.length; i++) {

      if (event.target.files[i].type == "image/png" || event.target.files[i].type == "image/jpg" || event.target.files[i].type == "image/jpeg") {
        let item = event.target.files[i]
        this.myFileTolApi.push(event.target.files[i]);
        this.getBase64(item)

      } else {
        this.myFiles.push(event.target.files[i]);
        this.myFileTolApi.push(event.target.files[i]);
      }
    }
  }

  getBase64(event) {
    let me = this;
    let reader = new FileReader();
    reader.readAsDataURL(event);
    reader.onload = function () {
      me.base64textString = reader.result;
      let obj = {
        base64: me.base64textString,
        type: "image",
      }
      me.myFiles.push(obj);
      console.log(me.myFiles)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  sendFiles() {
    if(this.myFileTolApi.length != 0){
      let result = []
      for(let i = 0 ; i < this.myFileTolApi.length ; i++){
        result.push(this.myFileTolApi[i])
      }
      result.push(this.objOfGift)
      this.spinner.show();
      console.log(result)
      this.rest.testInputFile(result).subscribe(res => {
        console.log(res)
        this.router.navigateByUrl('/confirm_upload');
        this.spinner.hide();
        // if (res == "0 files uploaded !") {
        //   this.spinner.hide();
        //   alert("لقد حدث خطأ ما برجاء اعادة المحاولة")
        // } else {
        //   this.spinner.hide();
        //   
        // }
      }
        , erorr => {
          this.spinner.hide();
          alert(JSON.stringify(erorr.error.Message))
          console.log(erorr.error)
        })
    }else{
      
      alert ('برجاء رفع الاعمال و الملفات الشخصية')
    }

  }

  delet(index){
    this.myFiles.splice(index,1)
    this.myFileTolApi.splice(index,1)
  }
}
