import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/service/rest.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.css']
})
export class NewsDetailsComponent implements OnInit {

  news_id ;
  newsObject = {
    date : "",
    thumbnail_url : "",
    title : "",
    detail : "",
    images : []
  }
  constructor(private rest : RestService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.news_id = this.route.snapshot.paramMap.get('id')
    this.getData()
  }

  getData(){
    this.rest.newsDetails(this.news_id).subscribe((res:any) => {
      console.log(res)
      this.newsObject.date = res.data
      this.newsObject.detail = res.detail
      this.newsObject.thumbnail_url = res.thumbnail_url
      this.newsObject.title = res.title
      this.newsObject.images = res.image_list
    })
  }

  imageObject = {
    cover_url : ""
  }

  getImage(item){
    console.log(item)
    this.imageObject.cover_url = item.url
  }

}
