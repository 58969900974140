import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './mainCopmponent/home/home.component';
import { ArtsComponent } from './pages/arts/arts.component';
import { CreativityComponent } from './pages/creativity/creativity.component';
import { LiteraryComponent } from './pages/literary/literary.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { CategorisComponent } from './pages/categoris/categoris.component';
import { SubmitFormCategoriComponent } from './pages/submit-form-categori/submit-form-categori.component';
import { AboutComponent } from './pages/about/about.component';
import { QuestionsComponent } from './pages/questions/questions.component';
import { PrizeRequirmentComponent } from './pages/prize-requirment/prize-requirment.component';
import { WinnersComponent } from './pages/winners/winners.component';
import { JudgerComponent } from './pages/judger/judger.component';
import { LegstlationComponent } from './pages/legstlation/legstlation.component';
import { UploadFilesComponent } from './pages/upload-files/upload-files.component';
import { ConfirmComponent } from './pages/confirm/confirm.component';
import { ConfirmUploadComponent } from './pages/confirm-upload/confirm-upload.component';
import { BooksComponent } from './pages/books/books.component';
import { NewsComponent } from './pages/news/news.component';
import { NewsDetailsComponent } from './pages/news-details/news-details.component';
import { VideosComponent } from './pages/videos/videos.component';



const routes: Routes = [
   { path: "", component: HomeComponent },
   { path: "home", component: HomeComponent },
   { path: "About", component: AboutComponent },
   { path: "Questions", component: QuestionsComponent },
   { path: "Prize_requirment", component: PrizeRequirmentComponent },
   { path: 'arts', component: ArtsComponent },
   { path: 'creativity', component: CreativityComponent },
   { path: 'literary', component: LiteraryComponent },
   { path: 'sign-Up', component: SignUpComponent },
   { path: 'categoris', component: CategorisComponent },
  { path: 'submitForm', component: SubmitFormCategoriComponent },
   { path: 'Winner', component: WinnersComponent },
   { path: 'judger', component: JudgerComponent },
   { path: 'legstlation', component: LegstlationComponent },
   { path: 'confirm', component: ConfirmComponent },
   { path: 'upload_files', component: UploadFilesComponent },
   { path: 'confirm_upload', component: ConfirmUploadComponent },
   { path: 'books', component: BooksComponent },
   { path: 'news', component: NewsComponent },
   { path: 'news_details/:id', component: NewsDetailsComponent },
   { path: 'videos', component: VideosComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes , {scrollPositionRestoration : 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
