import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/service/rest.service';

@Component({
  selector: 'app-judger',
  templateUrl: './judger.component.html',
  styleUrls: ['./judger.component.css']
})
export class JudgerComponent implements OnInit {

  wzeraImage; 
  wzeraPostion;
  wzeraName;
  wzeraParg;
  arrOfList
  date
  constructor(private rest : RestService) { }

  ngOnInit() {
    this.rest.GetHighCommittiee().subscribe(res => {
      this.arrOfList = res
      this.wzeraImage = res[0].C_IMAGE
      this.wzeraPostion = res[0].C_POSITION
      this.wzeraName = res[0].C_NAME
      this.wzeraParg = res[0].C_ABOUT
      this.arrOfList.splice(0,1)
    })
    this.rest.descrption().subscribe(res => {
      console.log(res)
      this.date = res[0].END_DATE
    })
  }

}
