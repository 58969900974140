import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/service/rest.service';

@Component({
  selector: 'app-legstlation',
  templateUrl: './legstlation.component.html',
  styleUrls: ['./legstlation.component.css']
})
export class LegstlationComponent implements OnInit {

  constructor(private rest : RestService) { }

  arrOfInfo;
  date
  ngOnInit() {
    this.getDate()
  }
   getDate(){
     this.rest.prizeLow().subscribe(res => {
       console.log(res)
       this.arrOfInfo = res
     })
     this.rest.descrption().subscribe(res => {
      console.log(res)
      this.date = res[0].END_DATE
    })
   }

}
