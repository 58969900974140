import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/service/rest.service';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

  questions;
  answer ;
  date
  constructor(private rest : RestService) { }

  ngOnInit() {
    this.rest.GetCommonQuestions().subscribe((res : any) => {
      console.log(res)
      this.questions = res
    })
    this.rest.descrption().subscribe(res => {
      console.log(res)
      this.date = res[0].END_DATE
    })
  }

  getAnswer(id){
    this.rest.getanswer(id).subscribe((res : any) => {
      console.log(res)
      this.answer = res.answer
    })
  }

}
