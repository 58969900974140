import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { RestService } from 'src/app/service/rest.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  winnersArr = []
  config = {
    format: 'dd:hh:mm:ss ',
    leftTime: 1641470999016,
    demand : false ,
    prettyText: (text) => {
      return text
        .split(':')
        .map((v) => `<span class="item">${v}</span>`)
        .join('');
    },
  }
  SlideOptions :OwlOptions = {
    rtl:true,
    items: 4,
    navText: ["<i class='fa fa-angle-left angleLeftReles'></i>", "<i class='fa fa-angle-right angleRightReles'></i>"],
    dots: false, nav: true,
  };
  date: any;
  data
  showSpnser = false
  isSubmit = true
  books_arr ;
  news_arr = [{
    thumbnail_url : "",
    title : ""
  },
  {
    thumbnail_url : "",
    title : ""
  }];
  getData() {

    this.winnersArr = [
      {
        name: "هنا أحمد سعيد",
        ageFrom: "5",
        ageTo: "12",
        categori: 'القصة',
        id : 1,
        image : "assets/img/winners/hana.jpg"
      },
      {
        name: "جولى رفيق اقلاديوس",
        ageFrom: "12",
        ageTo: "18",
        categori: 'القصة',
        id : 2,
        image : "assets/img/winners/goly.jpg"
      },
      {
        name: "لجين على السيد",
        ageFrom: "5",
        ageTo: "12",
        categori: 'القصة',
        id : 3,
        image : "assets/img/winners/lojin.jpg"
      },
      {
        name: "أحمد محمد عثمان",
        ageFrom: "12",
        ageTo: "18",
        categori: 'القصة',
        id : 4,
        image : "assets/img/winners/antr.jpg"
      },
      {
        name: "سيلينا سمير سعد",
        ageFrom: "5",
        ageTo: "12",
        categori: 'القصة',
        id : 5,
        image : "assets/img/winners/silina.jpg"
      },
      {
        name: "فريدة أحمد عبد العزيز",
        ageFrom: "12",
        ageTo: "18",
        categori: 'القصة',
        id : 6,
        image : "assets/img/winners/fareda.jpg"
      },
      {
        name: "جنا أحمد محمود",
        ageFrom: "5",
        ageTo: "12",
        categori: 'الفنون',
        id : 7,
        image : "assets/img/winners/jana.jpg"
      },
      {
        name: "مبروك حسين ناصر",
        ageFrom: "12",
        ageTo: "18",
        categori: 'الفنون',
        id : 8,
        image : "assets/img/winners/mabrok.jpg"
      },
      {
        name: "سما نور الدين",
        ageFrom: "5",
        ageTo: "12",
        categori: 'الفنون',
        id : 9,
        image : "assets/img/winners/sama.jpg"
      },
      {
        name: "رنا أحمد عبد الوهاب",
        ageFrom: "12",
        ageTo: "18",
        categori: 'الفنون',
        id : 11,
        image : "assets/img/winners/rana.jpg"
      },
      {
        name: "ريم أسامة صلاح",
        ageFrom: "5",
        ageTo: "12",
        categori: 'الفنون',
        id :12,
        image : "assets/img/winners/rim.jpg"
      },
      {
        name: "يوسف حسن سعد",
        ageFrom: "12",
        ageTo: "18",
        categori: 'الفنون',
        id :13,
        image : "assets/img/winners/yosof.jpg"
      },
      {
        name: "أياد معتز يوسف",
        ageFrom: "5",
        ageTo: "12",
        categori: 'الإبداع والابتكار',
        id :14,
        image : "assets/img/winners/eiad.jpg"
      },
      {
        name: "جاسر محمد جلال",
        ageFrom: "12",
        ageTo: "18",
        categori: 'الإبداع والابتكار',
        id :15,
        image : "assets/img/winners/jaser.jpg"
      },
      {
        name: "نادر مينا شاكر",
        ageFrom: "5",
        ageTo: "12",
        categori: 'الإبداع والابتكار',
        id : 17,
        image : "assets/img/winners/nader.jpg"
      },
      {
        name: "كرلس سامى جرجس",
        ageFrom: "12",
        ageTo: "18",
        categori: 'الإبداع والابتكار',
        id :18,
        image : "assets/img/winners/kerols.jpg"
      },
      
      {
        name: "محمد أبو بكر محمد",
        ageFrom: "12",
        ageTo: "18",
        categori: 'الإبداع والابتكار',
        id : 19,
        image : "assets/img/winners/mohamed.jpg"
      },
      

    ]
  }
  shareLink: SafeResourceUrl;
  VediosLink: SafeResourceUrl;
  showCount = false
  constructor(private rest : RestService ,  private _sanitizer: DomSanitizer,) { }

  ngOnInit() {
    this.getData()
    this.getVideos()
    this.rest.descrption().subscribe((res:any) => {
      console.log("sss",res)
      this.books_arr = res[0].Books
      this.news_arr = res[0].News
      this.showCount = res[0].SHOW_COUNT_DOWN
      console.log("books",res[0].books)
      if(res[0].is_submit == true){
        this.isSubmit = true
        localStorage.setItem("isSubmit","true")
        this.rest.sendObsData("data")
      }else {
        this.isSubmit = false
        localStorage.setItem("isSubmit","false")
        this.rest.sendObsData("data")
      }
      if(res[0].SHOW_AHLY_BANK_LOGO == false){
        this.showSpnser = false
      }else {
        this.showSpnser = true
      }
      if(res[0].SHOW_COUNT_DOWN == false){
        this.data = false
      }else {
        this.data = true
      }
      this.date = res[0].END_DATE
    })
  }
  getVideos(){
    this.rest.getvideos().subscribe(res => {
      console.log("videos",res)
      this.videosArr = res
      this.videosArr.forEach(element => {
        element.URL = this._sanitizer.bypassSecurityTrustResourceUrl(element.URL)
      });
    })
  }
  videosArr:any

  day;
  hours;
  mins;
  secs

  X = setInterval(() => {
    var futureDay = new Date(this.date).getTime()
    var curent = new Date().getTime()
    var distace =  futureDay - curent
    this.day = Math.floor(distace / (1000 * 60 * 60 * 24))
    this.hours = Math.floor((distace % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    this.mins = Math.floor((distace % (1000 * 60 * 60 )) / (1000 * 60))
    this.secs = Math.floor((distace % (1000 * 60 )) / (1000))
    // console.log(this.day , this.hours , this.mins , this.secs)
  },1000)
  
imageObject:any = {
  title : "",
  cover_url : ""
}
  openImage(item){
    this.imageObject = item
    console.log(item)
  }



}
