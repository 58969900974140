import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/service/rest.service';

@Component({
  selector: 'app-literary',
  templateUrl: './literary.component.html',
  styleUrls: ['./literary.component.css']
})
export class LiteraryComponent implements OnInit {
  date
  isSubmit
  item_one = {}
  item_tow = {}
  item_three = {}
  constructor(private rest :RestService) { }

  ngOnInit() {
    this.getData()
    this.isSubmit = localStorage.getItem("isSubmit")
    this.rest.descrption().subscribe(res => {
      this.date = res[0].END_DATE
    })
  }

  getData(){
    this.rest.categoriDetails(1).subscribe(res => {
      console.log(res)
      this.item_one = res[0]
      this.item_tow = res[1]
      this.item_three = res[2]
    })
  }

  goToDiv(value){
    document.getElementById(value).scrollIntoView();
  }
}
