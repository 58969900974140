import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var loginBtn = document.getElementById("login");
    var registerBtn = document.getElementById("register");


    loginBtn.onclick = function () {
      loginBtn.classList.add("d-none");
      registerBtn.classList.remove("d-none");
    }
    registerBtn.onclick = function () {
      registerBtn.classList.add("d-none");
      loginBtn.classList.remove("d-none");
    }
  }

}
