import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/service/rest.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private rest : RestService) { }
  aboutInfo : any;
  DEFINITION
  GOALS
  WINNER_SPONSORSHIP
  date
  ngOnInit() {
    this.getData()
  }

  getData(){
    this.rest.prizeReq().subscribe((res : any) => {
      this.aboutInfo = res[0]
      this.DEFINITION = this.aboutInfo.DEFINITION
      this.GOALS = this.aboutInfo.GOALS
      this.WINNER_SPONSORSHIP = this.aboutInfo.WINNER_SPONSORSHIP
      console.log(this.aboutInfo)

    })
    this.rest.descrption().subscribe(res => {
      console.log(res)
      this.aboutInfo = res[0]
      this.DEFINITION = this.aboutInfo.DEFINITION
      this.GOALS = this.aboutInfo.GOALS
      this.WINNER_SPONSORSHIP = this.aboutInfo.WINNER_SPONSORSHIP
      this.date = res[0].END_DATE
    })
  }

}
