import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule, HttpParams } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  private subject = new Subject<any>();

  private file_subject = new Subject<any>();

  constructor(private http: HttpClient) { }

  url = "https://api.ckp.eg"

  getGender() {
    return this.http.get(`${this.url}/api/InnovativeChild/GetGender`)
  }
  getNationlaty() {
    return this.http.get(`${this.url}/api/InnovativeChild/GetNationalities`)
  }

  getSpecifications() {
    return this.http.get(`${this.url}/api/InnovativeChild/GetFields`)
  }

  getSpecificationsbyId(id) {
    return this.http.get(`${this.url}/api/InnovativeChild/GetSubFieldsWithFieldID?fid=${id}`)
  }

  postFormSumbit(objBody) {
    return this.http.post(`${this.url}/api/InnovativeChild/SubmitApplicationForm`, objBody)
  }

  GetCommonQuestions() {
    return this.http.get(`${this.url}/api/InnovativeChild/GetCommonQuestions`)
  }
  getanswer(Qid) {
    return this.http.get(`${this.url}/api/InnovativeChild/CommonQuestions/${Qid}`)
  }
  aboutPrize() {
    return this.http.get(`${this.url}/api/InnovativeChild/GetCommonQuestions`)
  }

  prizeReq() {
    return this.http.get(`${this.url}/api/InnovativeChild/GetConditionsAndTerms`)
  }
  descrption() {
    return this.http.get(`${this.url}/api/InnovativeChild/getawardDescription`)
  }
  prizeLow() {
    return this.http.get(`${this.url}/api/InnovativeChild/getawardCreationLaw`)
  }

  GetHighCommittiee() {
    return this.http.get(`${this.url}/api/InnovativeChild/GetHighCommittiee`)
  }

  GetGovernate() {
    return this.http.get(`${this.url}/api/InnovativeChild/GetGovernate`)

  }

  // testInputFile(value, applicationFormID) {
  //   console.log(value)
  //   // let headers = new HttpHeaders();
  //   // headers = headers.set('Authorization', `bearer ${token}`);
  //   let formData = new FormData()
  //   for (var i = 0; i < value.length; i++) {
  //     formData.append("file[]", value[i]);
  //   }
  //   formData.append('file', JSON.stringify(value));
  //   return this.http.post(`${this.url}/api/InnovativeChild/UploadFile/${applicationFormID}`, formData)
  // }
  testInputFile(value){

    let formData = new FormData()
    for (var i = 0; i < value.length; i++) {
      if(value[i].status){
        formData.append("text", JSON.stringify(value[i]));
      }else {
        formData.append("file[]", value[i]);
      }
    }
    formData.append("file", JSON.stringify(value));

    return this.http.post(`${this.url}/api/InnovativeChild/SubmitApplicationFormWithFiles`,formData)
  }

  getWinners() {
    return this.http.get(`${this.url}/api/InnovativeChild/GetWinners`)
  }


  getBooks(page_index,count){
    return this.http.get(`${this.url}/api/Books/GetBooks?page_index=${page_index}&count=${count}`)
  }
  bookDetails(id){
    return this.http.get(`${this.url}/api/Books/GetBookDetail?book_id=${id}`)
  }
  getNews(page_index,count){
    return this.http.get(`${this.url}/api/News/GetNews?page_index=${page_index}&count=${count}`)
  }
  newsDetails(id){
    return this.http.get(`${this.url}/api/News/GetNewsDetails?news_id=${id}`)
  }
  sendObsData(event) {
    this.subject.next(event);
  }


  ADD_INSTAGRAM_VIEW(){
    return this.http.get(`${this.url}/api/InnovativeChild/ADD_INSTAGRAM_VIEW`)
  }
  ADD_FACEBOOK_VIEW(){
    return this.http.get(`${this.url}/api/InnovativeChild/ADD_FACEBOOK_VIEW`)
  }

  categoriDetails(id:any){
    return this.http.get(`${this.url}/api/InnovativeChild/GetSubFieldsWithFieldID?fid=${id}`)
  }

  getvideos(){
    return this.http.get(`${this.url}/api/InnovativeChild/GetVideos?page_index=1&count=10`)
  }

  getObsData(): Observable<any> {
    return this.subject.asObservable();
  }

  private files$ = new BehaviorSubject<any>({});
  file$ =  this.files$.asObservable();

  sendFile(event) {
    this.files$.next(event);
  }
  getFile(){
    return this.file_subject.asObservable();
  }
}

