import { Component, OnInit } from '@angular/core';
import { element } from 'protractor';
import { RestService } from 'src/app/service/rest.service';

@Component({
  selector: 'app-winners',
  templateUrl: './winners.component.html',
  styleUrls: ['./winners.component.css']
})
export class WinnersComponent implements OnInit {

  winnersArr = [];
  adabArr = []
  fnonArr = []
  abtkarArr = []
  winnersArrOne = [];
  adabArrOne = []
  fnonArrOne = []
  abtkarArrOne = []
  winnersArrTow = [];
  adabArrTow = []
  fnonArrTow = []
  abtkarArrTow = []
  winnersArrThree = []
  adabArrThree = []
  fnonArrThree= []
  abtkarArrThree = []
  constructor(private rest : RestService) { }

  ngOnInit() {
    this.rest.getWinners().subscribe(res => {
      console.log(res)
      this.winnersArr = res[1].Winners
      for(let i = 0 ; i < this.winnersArr.length ; i++){
        if(this.winnersArr[i].Artworks.length != 0){
          this.winnersArr[i].arts = this.winnersArr[i].Artworks[0].TITLE.split('-')
        }
        if(this.winnersArr[i].SUB_APPLICATION_FIELD == 'القصة' || this.winnersArr[i].SUB_APPLICATION_FIELD == 'الشعر' || this.winnersArr[i].SUB_APPLICATION_FIELD == 'التأليف المسرحى'){
          this.adabArr.push(this.winnersArr[i])
        }else if (this.winnersArr[i].SUB_APPLICATION_FIELD == 'الرسم' || this.winnersArr[i].SUB_APPLICATION_FIELD == 'العزف' || this.winnersArr[i].SUB_APPLICATION_FIELD == 'الغناء'){
          this.fnonArr.push(this.winnersArr[i])
        }else {
          this.abtkarArr.push(this.winnersArr[i])
        }
      }
      this.winnersArrOne = res[0].Winners
      for(let i = 0 ; i < this.winnersArrOne.length ; i++){
        if(this.winnersArrOne[i].Artworks.length != 0){
          this.winnersArrOne[i].arts = this.winnersArrOne[i].Artworks[0].TITLE.split('-')
        }
        if(this.winnersArrOne[i].SUB_APPLICATION_FIELD == 'القصة' || this.winnersArrOne[i].SUB_APPLICATION_FIELD == 'الشعر' || this.winnersArrOne[i].SUB_APPLICATION_FIELD == 'التأليف المسرحى'){
          this.adabArrOne.push(this.winnersArrOne[i])
        }else if (this.winnersArrOne[i].SUB_APPLICATION_FIELD == 'الرسم' || this.winnersArrOne[i].SUB_APPLICATION_FIELD == 'العزف' || this.winnersArrOne[i].SUB_APPLICATION_FIELD == 'الغناء'){
          this.fnonArrOne.push(this.winnersArrOne[i])
        }else {
          this.abtkarArrOne.push(this.winnersArrOne[i])
        }
      }

      this.winnersArrTow = res[2].Winners
      for(let i = 0 ; i < this.winnersArrTow.length ; i++){
        if(this.winnersArrTow[i].Artworks.length != 0){
          this.winnersArrTow[i].arts = this.winnersArrTow[i].Artworks[0].TITLE.split('-')
        }
        if(this.winnersArrTow[i].SUB_APPLICATION_FIELD == 'القصة' || this.winnersArrTow[i].SUB_APPLICATION_FIELD == 'الشعر' || this.winnersArrTow[i].SUB_APPLICATION_FIELD == 'التأليف المسرحى'){
          this.adabArrTow.push(this.winnersArrTow[i])
        }else if (this.winnersArrTow[i].SUB_APPLICATION_FIELD == 'الرسم' || this.winnersArrTow[i].SUB_APPLICATION_FIELD == 'العزف' || this.winnersArrTow[i].SUB_APPLICATION_FIELD == 'الغناء'){
          this.fnonArrTow.push(this.winnersArrTow[i])
        }else {
          this.abtkarArrTow.push(this.winnersArrTow[i])
        }
      }

      this.winnersArrThree = res[3].Winners
      for(let i = 0 ; i < this.winnersArrThree.length ; i++){
        if(this.winnersArrThree[i].Artworks.length != 0){
          this.winnersArrThree[i].arts = this.winnersArrThree[i].Artworks[0].TITLE.split('-')
        }
        if(this.winnersArrThree[i].SUB_APPLICATION_FIELD == 'القصة' || this.winnersArrThree[i].SUB_APPLICATION_FIELD == 'الشعر' || this.winnersArrThree[i].SUB_APPLICATION_FIELD == 'التأليف المسرحى'){
          this.adabArrThree.push(this.winnersArrThree[i])
        }else if (this.winnersArrThree[i].SUB_APPLICATION_FIELD == 'الرسم' || this.winnersArrThree[i].SUB_APPLICATION_FIELD == 'العزف' || this.winnersArrThree[i].SUB_APPLICATION_FIELD == 'الغناء'){
          this.fnonArrThree.push(this.winnersArrThree[i])
        }else {
          this.abtkarArrThree.push(this.winnersArrThree[i])
        }
      }
      console.log(this.winnersArrTow)
    })
  }


  goToDiv(value){

    document.getElementById(value).scrollIntoView();
  }
}
