import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/service/rest.service';

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.css']
})
export class BooksComponent implements OnInit {

  currentPage = 1
  count = 100
  list_of_books;
  imageObject:any = {
    title : "",
    cover_url : ""
  }
  constructor(private rest : RestService) { }

  ngOnInit() {
    this.getBooks()
  }

  getBooks(){
    this.rest.getBooks(this.currentPage,this.count).subscribe(res => {
      console.log(res)
      this.list_of_books = res
    })
  }

  DowneLoadBook(item){
    this.imageObject = item
    // this.rest.bookDetails(id).subscribe((res:any) => {
    //   console.log(res)
    //   window.open(res.file_path)
    // })
  }

}
